import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId:sessionStorage.getItem('userId')?sessionStorage.getItem('userId'):'',
    roleId:sessionStorage.getItem('roleId')?sessionStorage.getItem('roleId'):'',
    brandId:sessionStorage.getItem('brandId'),
    ind:sessionStorage.getItem('index') || 0,
    //全局存储角色数据
    roleList:[
      {
        id:2,
        name:'管理员'
      },
      {
        id:3,
        name:'学员'
      },
      {
        id:4,
        name:'导师'
      },
      {
        id:5,
        name:'审核员'
      },
      {
        id:10,
        name:'院长'
      }
    ],
    brandList:[
      {
        id:1,
        name:'嘉顿肯妮'
      },
      {
        id:2,
        name:'瑞妮朵拉'
      },
      {
        id:3,
        name:'素颜+'
      },
      {
        id:5,
        name:'修芙缇'
      },
      {
        id:10,
        name:'外部学员'
      },
      // {
      //   id:11,
      //   name:'测试数据'
      // },
    ],
    token:sessionStorage.getItem('token'),
    permissionsList:sessionStorage.getItem('permissions')!='undefined'?JSON.parse(sessionStorage.getItem('permissions')):[],
    userInfo:sessionStorage.getItem('userInfo')!='undefined'?JSON.parse(sessionStorage.getItem('userInfo')):{}
  },
  mutations: {
    setToken(state,data){//保存token
      state.token=data
      sessionStorage.setItem('token', data)
    },
    setBrandId(state,data){//保存品牌
      state.brandId=data
      sessionStorage.setItem('brandId', data)
    },
    setUserId(state,data){//保存用户id
      state.userId=data
      sessionStorage.setItem('userId', data)
    },
    setRoleId(state,data){//保存用户roleId
      state.roleId=data
      sessionStorage.setItem('roleId', data)
    },
    setPermissionsList(state,data){//保存权限
      state.permissionsList=data
      sessionStorage.setItem('permissions',JSON.stringify(data))
    },
    setUserInfo(state,data){//保存用户角色信息
      state.userInfo=data
      sessionStorage.setItem('userInfo',JSON.stringify(data))
    },
    setIndex(state,data){
      state.ind=data
      sessionStorage.setItem('index',data)
    }
  },
  actions: {
  },
  modules: {
  }
})


